import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import RcTableViewUtils from "../../../shared/table/RcTableViewUtils";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
// import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
// import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = StringUtils;
  }

  start() {
    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderItems();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderItems() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Address"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Lisense"))
      );

    var boxContent = new BoxDisplayRow()
    var even = true;
    //var nameFormat = "{0} ({1})";

    var list = this.model().companies();
    for (var index = 0; index < list.length; index++) {
      var company = list[index];
      var settings = this.model().findInvoiceSettings(company.invoiceSettings());
      var card = this.model().findCard(settings.card());

      var companyBox = new BoxDisplayRow().withWidth("15")
      companyBox
        .withChild(new LabelValueDisplayRow()
          .withName("Company Name")
          .withValue(company.name())
          .withField("name")
          .withObject(company.id())
        )
        .withChild(new LabelValueDisplayRow()
          .withName("QB CustomerId")
          .withValue(company.integrationReference())
          .withField("integrationReference")
          .withObject(company.id())
        )
        .withChild(
          new LabelValueDisplayRow()
          .withName("Card Name")
          .withValue(card.name())
          .withField("cardName")
          .withObject(card.id())
        )
        .withChild(
          new LabelValueDisplayRow()
          .withName("Card Id")
          .withValue(settings.cardId() + " / " + card.integrationReference())
          .withField("cardId")
          .withObject(card.id())
        )
        .withChild(
          new LabelValueDisplayRow()
          .withName("Card No")
          .withValue(card.number())
          .withField("cardNumber")
          .withObject(card.id())
        )
        .withChild(
          new LabelValueDisplayRow()
          .withName("QB Card Data")
          .withValue(card.getString("qbCard"))
          .withField("cardNumber")
          .withObject(card.getString("qbCard"))
        )
        ;

      var actionsRow = new RowDisplayRow();
      actionsRow
        .withChild(new StringDisplayRow().withWidth("11"))
        .withChild(
          new ButtonDisplayRow().withWidth("2").withName("Remove").withKey("remove").withField("CardRemoveConfirm").withObject(company.id())
        )
        .withChild(
          new ButtonDisplayRow().withWidth("2").withName("Fetch QB Info").withKey("fetchQbInfo").withField("CardFetchButton").withObject(company.id())
        )
        ;
      var buttonsBox = new BoxDisplayRow();
      buttonsBox.withChild(actionsRow);

      boxContent
        .withBorder(false)
        .withChild(
          new BoxDisplayRow()
            .withBorder(true)
            .withEven(even)
            .withChild(
              new RowDisplayRow()
                .withBorder(false)
                  .withChild(companyBox)
              )
            .withChild(buttonsBox)
          );
      even = !even;
    }

    box.withChild(boxContent).done();
    this.add(box);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(this.model().pageNumber(query))
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Filters"))
          .withChild(new SpacerDisplayRow().withWidth("13"))
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Status"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("all").withField("Status").withDefaultValue("all").withSelected(query["status"] === "all"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Active").withKey("active").withField("Status").withDefaultValue("active").withSelected(query["status"] === "active" || !query["status"]))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Invited").withKey("inactive").withField("Status").withDefaultValue("invited").withSelected(query["status"] === "invited"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Waiting to be Invited").withKey("majorStatus").withField("Status").withDefaultValue("notinvited").withSelected(query["status"] === "notinvited"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Inactive").withKey("inactive").withField("Status").withDefaultValue("inactive").withSelected(query["status"] === "inactive"))
              .withChild(new SpacerDisplayRow().withWidth("8"))
            )
            .withChild(new RowDisplayRow().withBorder(false)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
              .withChild(new StringUpdateRow().withWidth("5")
                  .withShowLabel(false).withField("Search")
                  .withKey("Search").withName("Search")
                  .withDefaultValue(query["search"] ? query["search"] : ""))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
              .withChild(new SpacerDisplayRow().withWidth("7"))
            )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );
  }

}