
import RcDomainMap from "../../common/RcDomainMap.js";
import Card from './Card.js';

export default class CardMap extends RcDomainMap {
  static MODEL_NAME = Card.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data);
  }

  getCard(key) {
    var value = super.get(key);
    if (value) {
      return new Card(this.domain, value);
    }
    return new Card(this.domain);
  }

  copy() {
    var data = this._copy();
    return new CardMap(this.domain, data);
  }
}