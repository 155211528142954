<template>
    <Box>
  
      <Row>
        <Column :width="15">
          <Box :key="redraw">
            <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></WorkingDisplayRow>
            <RemoveConfirmDialogRow v-if="this.showDialog == 'removeCardConfirm'" :cardId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></RemoveConfirmDialogRow>
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import RemoveConfirmDialogRow from './remove/RemoveConfirmDialogRow.vue';

export default {
    name: "card-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
      WorkingDisplayRow,
      RemoveConfirmDialogRow,
    },
    props: {
      isAdmin: { type: Boolean, default: false },
    },
    data() {
      return {
        redraw: 1,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        showMissingRequirements: false,
        showDetails: false,
        showEmail: false,
        showRemarks: false,
        showWorking: false,
        showTimesheets: false,
        showInspections: false,
        showDialog: "",
        selectedId: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from){
        if (to && from) {
          if (to.query["action"] === "working") {
            this.showDialog = "workingDialog";

          } else if (to.query["action"] === "removeCardConfirm") {
            this.selectedId = to.query["selectedId"];
            this.showDialog = "removeCardConfirm";

          } else if (to.query["action"] === "fetchCardAction") {
            this.selectedId = to.query["selectedId"];
            this.fetchCardAction(this.selectedId);

          } else {
            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = "";
            } else {
              this.reloadPage();
            }
          }
        }
      }
    },
    mounted: function () {
      this.viewModel = new this.ListViewModel(this);
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewData = this.viewModel.viewData();
        this.reloadPage();
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      showDetailsIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        if (query["action"] === "userDetails") {
          //
        } 
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        row.withMessage(msg);
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },
      
      urlQueryParams() {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        return query;
      },

      addQueryParams(valueData, key) {
        var query = this.urlQueryParams();
        this.addQueryParamsToRouter(query, valueData, key);
      },

      addQueryParamsToRouter(query, valueData, key) {
        query[key] = valueData["value"];
        if (key === "pageSize") {
          delete query.page
        }
        this.$router.push({ query });
      },

      withValue: function(valueData) {
        this.model().withValue(valueData);
        
        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData);
          this.addQueryParams(valueData, "pageSize");
        }
        if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");
        }
        
        if (valueData["field"] === "Search") {
          const last = this.model().searchText();
          this.model().withSearchText(valueData);
          if (last === this.model().searchText()) {
            this.withButtonPressed(valueData);
          }
        }
      },

      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);

        if (buttonData["field"] === "DetailsClose") {
          var query = Object.assign({}, this.$route.query);
          query = this.deleteDetails(query);
          this.$router.push({ query });
          if (buttonData["removed"]) {
            this.reloadPage();
          }
        }

        if (buttonData["field"] === "CardRemoveConfirm") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "removeCardConfirm";
          query["selectedId"] = this.Contents.baseEncode(buttonData["object"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "CardFetchButton") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "fetchCardAction";
          query["selectedId"] = this.Contents.baseEncode(buttonData["object"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Refresh") {
          this.reloadPage();
        }

        if (buttonData["field"] === "NextPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "PreviousPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }
        }
        
        if (buttonData["field"] === "Search") {
          buttonData["value"] = this.model().searchText();
          const queryParams = this.urlQueryParams();
          delete queryParams["page"];
          this.addQueryParamsToRouter(queryParams, buttonData, "search");
        }

        if (buttonData["field"] === "Clear") {
          this.model().withSearchText("");
          buttonData["value"] = "";
          this.addQueryParams(buttonData, "search");
        }
      },
      
      fetchCardAction(selectedId) {
        var companyId = this.Contents.baseDecode(selectedId);
        var company = this.domain.companys().findById(companyId);
        var event = this.domain
                      .events()
                      .invoice()
                      .listCards(company.map());
        var context = {};
        event.send(this.fetchCardListener, context);
      },

      fetchCardListener(notification, context) {
        if (notification && context) {
          //
        }
        if (notification.error()) {
          this.viewModel.withError(notification.error());
        }
        setTimeout(this.reloadPage, 500);
      },

      deleteDetails(query) {
        delete query.action;
        delete query.selectedId;
        return query;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },

      paint: function() {
        this.redraw++;
      },

    },
  }
</script>