
import RcDomainMap from "../../common/RcDomainMap.js";
import InvoiceSettings from "./InvoiceSettings.js";

export default class InvoiceSettingsMap extends RcDomainMap {
  static MODEL_NAME = InvoiceSettings.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, InvoiceSettingsMap.MODEL_NAME);
  }

  getSettings(key) {
    var value = super.get(key);
    if (value) {
      return new InvoiceSettings(this.domain, value);
    }
    return new InvoiceSettings(this.domain);
  }

  copy() {
    var data = this._copy();
    return new InvoiceSettingsMap(this.domain, data);
  }
}